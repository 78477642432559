<template>
  <div>
    <Loading v-if="dashboardLoading" />
    <div v-if="showDashboard">
      <div
        class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-2 mb-16"
      >
        <div
          class="flex flex-col rounded-md shadow-md bg-white p-1"
          v-for="(data, index) in cardList"
          :key="index"
        >
          <!-- cols="12" -->
          <!-- md="6"
                    lg="2" -->
          <div class="flex items-center space-x-4">
            <div>
              <img
                class="w-12"
                :src="require(`@/assets/icon/${data.icon}`)"
                alt="invoice"
              />
            </div>
            <h4
              class="text-base text-theme-blue font-semi"
              :class="{
                'text-truncate': data.value !== 'newMember',
              }"
              v-text="$t(data.title)"
            />
            <!-- <h3 class="text-sm text-theme-blue font-semi">{{ $t('billedThisMonth')}}</h3> -->
          </div>
          <div class="flex justify-center">
            <h3 class="text-2xl text-gray-800 font-semibold">
              {{ data.amount }}
            </h3>
          </div>
          <!-- <b-card class="bg-red-400" style="height: 121px">
                        <b-card-text>
                            <b-media>
                                <template #aside>
                                    <b-img
                                        width="50px"
                                        :src="
                                            require(`@/assets/icon/${data.icon}`)
                                        "
                                    />
                                </template>

                                <h4
                                    class="mt-0 text-secondaryColor"
                                    :class="{
                                        'text-truncate':
                                            data.value !== 'newMember',
                                    }"
                                    v-text="$t(data.title)"
                                />
                                <div
                                    class="d-flex justify-content-start justify-content-lg-center position-relative"
                                >
                                    <p
                                        class="mb-0 h2 d-none d-lg-block position-absolute text-right"
                                        :class="watchCardStyle(data)"
                                        v-text="data.amount"
                                    />
                                    <p
                                        class="mb-0 h2 d-block d-lg-none position-absolute text-right"
                                        v-text="data.amount"
                                    />
                                </div>
                            </b-media>
                        </b-card-text>
                    </b-card> -->
        </div>
      </div>

      <!-- <b-row>

            </b-row>
            <div class="mb-5">
                
            </div> -->

      <b-row>
        <b-col cols="8">
          <b-card :title="$t(locale.revenue)">
            <b-card-text>
              <apexchart type="area" :options="options" :series="series" />
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="6" lg="8">
          <b-card class="rounded-lg" style="min-height: 300px">
            <b-card-title
              class="d-flex align-items-center pt-1 justify-content-between"
            >
              <div class="d-flex align-items-center" style="gap: 1.5rem">
                <p class="mb-0 pb-0 h4" v-text="$t(locale.unpaidLabel)" />
              </div>
            </b-card-title>

            <b-card-text>
              <b-table
                show-empty
                responsive
                sticky-header
                hover
                class="scrol-area"
                :items="items"
                :fields="fields"
              >
                <template #head(invoice_number)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="$t(data.field.label)"
                    />
                  </div>
                </template>

                <template #head(customer_name)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="$t(data.field.label)"
                    />
                  </div>
                </template>

                <template #head(amount)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="$t(data.field.label)"
                    />
                  </div>
                </template>
                <template #head(invoice_date)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="$t(data.field.label)"
                    />
                  </div>
                </template>
                <template #head(due_date)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="$t(data.field.label)"
                    />
                  </div>
                </template>

                <template #cell(invoice_number)="data">
                  <div class="d-flex ml-1 justify-content-center">
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="data.value"
                    />
                  </div>
                </template>

                <template #cell(customer_name)="data">
                  <div class="d-flex ml-1 justify-content-center">
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="data.value"
                    />
                  </div>
                </template>

                <template #cell(amount)="data">
                  <div
                    class="d-flex ml-1 justify-content-center"
                    v-tableStyle="data.field.thStyle.width"
                  >
                    <span
                      class="my-auto font-weight-bold"
                      style="margin-right: 10px"
                      v-text="data.value"
                    />
                  </div>
                </template>

                <template #cell(invoice_date)="data">
                  <div class="d-flex ml-1 justify-content-center">
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="data.value"
                    />
                  </div>
                </template>
                <template #cell(due_date)="data">
                  <div class="d-flex ml-1 justify-content-center">
                    <span
                      class="my-auto"
                      style="margin-right: 10px"
                      v-text="data.value"
                    />
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6" lg="4">
          <Notification />
        </b-col>
      </b-row>
    </div>
    <CustomNoHeaderModalVue
      v-if="showIntegrationPopup"
      width="w-11/12 md:w-2/3"
      :title="''"
      :backdropOff="true"
      @handleClose="showIntegrationPopup = false"
      :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
          <h2 class="text-xl md:text-2xl font-semibold font-poppins text-center text-gray-900">
            {{ $t("ActionPopup.activateGIT") }}
          </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
          <p class="text-center text-xl text-gray-900">
            {{ $t("ActionPopup.activateGITDetails") }}
          </p>
          <div class="flex flex-col justify-center mx-auto md:px-10">
              <button
                  @click="redirectToActivationPage"
                  class="flex justify-center focus:outline-none c-p-3 text-white rounded-md bg-theme-sidebar mt-1"
              >
                {{ $t("ActionPopup.activate") }}
              </button>
          </div>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue
      v-if="openConnectInfo"
      width="w-11/12 md:w-1/3"
      :title="''"
      :backdropOff="true"
      @handleClose="openConnectInfo = false"
      :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
          class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          {{ $t(locale.createFeeRule) }}
        </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <!-- If regular welcome message is to be shown -->
        <p
          class="text-center text-xl text-gray-900"
          v-if="!connectionData['re-connect'] && !connectionData['installation-needed']"
        >
          {{ $t("ActionPopup.welcome") }}
        </p>
        <!-- If welcome message incl. info about installation is to be shown -->
        <div v-else-if="!connectionData['re-connect'] && connectionData['installation-needed']">
          <p class="text-center text-xl text-gray-900 font-bold">
            {{ $t("ActionPopup.installationWelcome") }}
          </p>
          <p class="text-center">{{ $t("ActionPopup.installationNeeded") }}</p>
        </div>
        <!-- If reconnect message regarding offline connector is to be shown -->
        <div v-else-if="connectionData['re-connect'] && connectionData['connector-offline']">
          <p class="text-center text-xl text-gray-900 font-bold">
            {{ $t("ActionPopup.connectorOfflineTitle") }}
          </p>
          <p class="text-center">{{ $t("ActionPopup.connectorOffline") }}</p>
        </div>

        <!-- If reconnect needed message is to be shown -->
        <div v-else>
          <p class="text-center text-xl text-gray-900 font-bold">
            {{ $t("ActionPopup.needAttention") }}
          </p>
          <p class="text-center">{{ $t("ActionPopup.lostConnection") }}</p>
        </div>
        
        <div class="flex flex-col justify-center mx-auto md:px-10">
          <span class="px-2"><img :src="connectionData.image" alt="" /></span>
          <button
            @click="connectIntegration"
            class="flex justify-center focus:outline-none c-p-3 text-white rounded-md bg-theme-sidebar mt-1"
          >
            <span v-if="processing">{{ $t("Message.loading") }}</span>
            <span v-else-if="connectionData['installation-needed'] || connectionData['connector-offline']"> {{ $t("ActionPopup.contact") }} </span>
            <span v-else> {{ $t("ActionPopup.connect") }} </span>
          </button>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import Notification from "@/@core/components/dashboard/Notification.vue";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Notification,
    CustomNoHeaderModalVue,
  },

  data() {
    return {
      config: useAppConfig(),
      dashboardLoading: true,
      showDashboard: false,
      openConnectInfo: false,
      showIntegrationPopup: false,
      processing: false,
      connectionData: {},
      series: [],
      options: {
        xaxis: {
          label: {
            show: true,
          },
          title: {
            text: this.$t("Message.month"),
            style: {
              fontSize: "20px",
              fontWeight: 600,
              cssClass: "chartTitle",
            },
          },
          categories: [],
        },
        title: {
          text: this.$t("dashboardCard.chartTitle"),
          align: "left",
        },
      },
      items: [],
      locale: {
        unpaidLabel: "dashboardCard.unpaidInvoice",
        revenue: "dashboardCard.revenueLabel",
      },
      cardList: [
        {
          title: "dashboardCard.billedmonth",
          value: "billedmonth",
          icon: "invoices.png",
          amount: "",
          properties: "billed_this_month",
        },
        {
          title: "dashboardCard.unpaid",
          icon: "order.png",
          value: "unpaid",
          amount: "",
          properties: "num_unpaid_overdue_invoices",
        },
        // {
        //   title: "dashboardCard.newMember",
        //   value: "newMember",
        //   icon: "newUsers.png",
        //   amount: "",
        //   properties: "num_new_members_this_month",
        // },
      ],
      fields: [
        {
          key: "invoice_number",
          label: "dashboardCard.table.invoiceHead",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "130px",
          },
        },
        {
          key: "customer_name",
          label: "dashboardCard.table.customerHead",
          tdClass: "bTableThStyle",
          thStyle: { padding: "0.72rem 0", width: "150px" },
        },
        {
          key: "amount",
          label: "dashboardCard.table.amountHead",
          tdClass: "bTableThStyle",
          thStyle: { padding: "0.72rem 0", width: "90px" },
        },
        {
          key: "invoice_date",
          label: "dashboardCard.table.invoiceDateHead",
          tdClass: "bTableThStyle",
          thStyle: { padding: "0.72rem 0", width: "130px" },
        },
        {
          key: "due_date",
          label: "dashboardCard.table.dueDateHead",
          tdClass: "bTableThStyle",
          thStyle: { padding: "0.72rem 0", width: "120px" },
        },
      ],
      fieldsItem: [
        {
          options: [
            1345690,
            "Toby Olsson",
            "3400 SEK",
            "2022-06-30",
            "2022-07-30",
          ],
        },
        {
          options: [
            1345690,
            "Toby Olsson",
            "3400 SEK",
            "2022-06-30",
            "2022-07-30",
          ],
        },
        {
          options: [
            1345690,
            "Toby Olsson",
            "3400 SEK",
            "2022-06-30",
            "2022-07-30",
          ],
        },
      ],
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    cardTitle() {
      return `${this.$t("Message.welcome")} ${this.$t("Message.back")}, ${
        this.userData.full_name
      }!`;
    },
    customMsg() {
      if (this.getLang == "") {
        return this.swedishLang ? "message" : "eng_message";
      } else return this.getLang == "sv" ? "message" : "eng_message";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: "appConfig/getLocale",
      CURRENT_USER: "appConfig/currentUser",
    }),
  },

  watch: {
    getLang() {
      //Update Chart options
      this.options = {
        xaxis: {
          title: {
            text: this.$t("Message.month"),
            style: {
              fontSize: "20px",
              fontWeight: 600,
              cssClass: "chartTitle",
            },
          },
        },
        title: {
          text: this.$t("dashboardCard.chartTitle"),
        },
      };
    },
  },

  methods: {
    isObjectNotEmpty(obj) {
      if (obj && typeof obj === "object" && Object.keys(obj)?.length > 0) {
        return true;
      }
      return false;
    },

    watchCardStyle(data) {
      if (data.title && data.value !== "newMember") {
        return "top23";
      } else if (data.value == "") return "top41";
    },

    getDashboard() {
      this.$useJwt.getDashboardStats().then((res) => {
        const { data } = res.data;

        this.cardList.forEach((item) => {
          item.amount = data[item.properties];
        });

        this.items = data.unpaid_invoices;

        this.options = {
          chart: {
            id: "apex",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: this.months.slice(
              0,
              Object.values(res.data.data.billed_per_month).length
            ),
          },
          tooltip: {
            enabled: true,
            x: {
              show: false,
            },
          },
        };

        this.series = [
          {
            name: this.$t("dashboardCard.chartTitle"),
            data: Object.keys(data.billed_per_month).map((item) =>
              parseInt(data.billed_per_month[item])
            ),
          },
        ];

        this.dashboardLoading = false;
        this.showDashboard = true;
      });
    },
    connectIntegration() {
      this.processing = true;
      // Check if installation is needed
      if (this.connectionData['installation-needed']) {
        // Redirect to an email address
        window.location.href = "mailto:info@golfigo.se"; 
        this.processing = false;
        localStorage.removeItem("__connectInfo__");
        return;
      } 

      if (this.connectionData['connector-offline']) {
        window.location.href = "mailto:info@golfigo.se"; 
        this.processing = false;
        localStorage.removeItem("__connectInfo__");
        return;
      }

      const URL = this.connectionData.connect_endpoint.replace("/v1", "");
      this.$useJwt
        .useBaseURLOnly(`${URL}`)
        .then((res) => {
          this.processing = false;
          localStorage.removeItem("__connectInfo__");
          window.location.replace(res.data.auth_link);
        })
        .catch((err) => {
          this.processing = false;
          if (err.response && err.response.data) {
            if (err.response.data.error) {
              this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data.error,
                "AlertTriangleIcon",
                "danger"
              );
            } else if (this.customMsg && err.response.data[this.customMsg]) {
              this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.customMsg],
                "AlertTriangleIcon",
                "danger"
              );
            }
          }
        });
    },

    redirectToActivationPage() {
      window.location.href = "https://gitonline.se/login/Account/Login";
    }
  },

  mounted() {
    this.$store.commit("appConfig/UPDATE_ACCESS_DENIED", false);
    this.getDashboard();
    const showIntegrationPopupFlag = localStorage.getItem("showIntegrationPopup");
    if (showIntegrationPopupFlag) {
        this.showIntegrationPopup = true;
        localStorage.removeItem("showIntegrationPopup");  // remove the flag
    }

    //NOTE Needed to get updated user profile (including prop <connectInfo>) in dashboard
    this.$useJwt.customUser({ URL: "/me", method: "get" }).then((resp) => {
      if (
        resp?.data?.data?.golfclub && 
        resp?.data?.data?.golfclub.is_git_api_user && 
        !resp?.data?.data?.golfclub.git_activated
      ) {
          localStorage.setItem("showIntegrationPopup", "true");
          this.showIntegrationPopup = true;
      } else {
        localStorage.removeItem("showIntegrationPopup");
      }
      if (
        resp?.data?.data?.connect_info &&
        resp?.data?.data?.connect_info.connect_endpoint
      ) {
        const connect = JSON.stringify(resp?.data?.data?.connect_info);
        localStorage.setItem("__connectInfo__", connect);

        this.connectionData = JSON.parse(connect);
        this.openConnectInfo = true;
      } else {
        localStorage.removeItem("__connectInfo__");
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.top23 {
  top: 23px;
}

.top41 {
  top: 41px;
}

.scrol-area {
  overflow: auto;
  scrollbar-color: $primary transparent;
}
</style>
